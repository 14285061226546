
#adopt-controller-button {
	bottom: 60px !important;
}

#chat-widget-container {
	bottom: 46px !important;
}

#adopt-controller-button img {
	height: 40px !important;
	width: 40px !important;
}

@media (max-width: 768px) {
	#adopt-controller-button {
		bottom: 70px !important;
	}
	#chat-widget-container {
		bottom: 56px !important;
	}
	#chat-widget {
		position: fixed !important;
	}
}

.dots-loader {
	width: 60px;
	aspect-ratio: 4;
	background: radial-gradient(circle closest-side,#000 90%,#0000) 0/calc(100%/3) 100% space;
	clip-path: inset(0 100% 0 0);
	animation: l1 1s steps(4) infinite;
  }
  @keyframes l1 {to{clip-path: inset(0 -34% 0 0)}}